import { Container } from '@mui/material';
import { Helmet } from 'react-helmet-async';
// components
import CreateSessionForm from 'src/components/whatsapp/session/CreateSessionForm';
// sections
import { useSettingsContext } from '../../../theme/sections/settings';
import CustomBreadcrumbs from '../../../theme/sections/custom-breadcrumbs';

// ----------------------------------------------------------------------

export default function CreateSessionPage() {
  const { themeStretch } = useSettingsContext();

  return (
    <>
      <Helmet>
        <title> Create Whatsapp Session | {process.env.REACT_APP_NAME} </title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Create a new session"
          links={[
            {
              name: 'Dashboard',
              href: '/dashboard',
            },
            {
              name: 'Whatsapp',
              href: '/whatsapp',
            },
            {
              name: 'Session',
            },
          ]}
        />

        <CreateSessionForm />
      </Container>
    </>
  );
}
