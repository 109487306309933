import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const sessionApi = createApi({
  reducerPath: 'sessionApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL + '/sessions',
    //set header
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.user.accessToken;
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Session'],
  endpoints: (builder) => ({
    // Get active list
    activeList: builder.query({
      query: () => '/list/active',
      providesTags: ['Session'],
    }),

    // Get user status
    statusSession: builder.query({
      query: (sessionId) => ({
        url: `/status/${sessionId}`,
        method: 'GET',
      }),
    }),

    // Check Session
    checkSession: builder.mutation({
      query: (sessionId) => ({
        url: `/check/${sessionId}`,
        method: 'GET',
      }),
    }),

    // create Session
    createSession: builder.mutation({
      query: (data) => ({
        url: '/create',
        method: 'POST',
        body: data,
      }),
    }),
    // start Session
    startSession: builder.mutation({
      query: (sessionId) => ({
        url: `/start/${sessionId}`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useCreateSessionMutation,
  useActiveListQuery,
  useStatusSessionQuery,
  useStartSessionMutation,
  useCheckSessionMutation,
} = sessionApi;
