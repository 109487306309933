import { Container } from '@mui/material';
import { Helmet } from 'react-helmet-async';
// components
import CreateUserForm from '../../components/user/CreateUserForm';
// sections
import { useSettingsContext } from '../../theme/sections/settings';
import CustomBreadcrumbs from '../../theme/sections/custom-breadcrumbs';

// ----------------------------------------------------------------------

export default function CreateGroupPage() {
  const { themeStretch } = useSettingsContext();

  return (
    <>
      <Helmet>
        <title> Create User | {process.env.REACT_APP_NAME} </title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Create a new user"
          links={[
            {
              name: 'Dashboard',
              href: '/dashboard',
            },
            {
              name: 'User',
              href: '/user',
            },
            {
              name: 'Create',
            },
          ]}
        />

        <CreateUserForm />
      </Container>
    </>
  );
}
