import PropTypes from 'prop-types';
import { useState } from 'react';
import { sentenceCase } from 'change-case';
// @mui
import {
  Stack,
  Button,
  TableRow,
  Checkbox,
  MenuItem,
  TableCell,
  IconButton,
  Chip,
  Avatar,
  Typography,
  Switch,
} from '@mui/material';
// utils
import { fDateTime } from '../../../../theme/utils/formatTime';
import { fCurrency } from '../../../../theme/utils/formatNumber';
// sections
import Label from '../../../../theme/sections/label';
import Image from '../../../../theme/sections/image';
import Iconify from '../../../../theme/sections/iconify';
import MenuPopover from '../../../../theme/sections/menu-popover';
import ConfirmDialog from '../../../../theme/sections/confirm-dialog';
import { useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

UserSessionTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onViewRow: PropTypes.func,
};

export default function UserSessionTableRow({ row, selected, onViewRow }) {
  const { status, created_at } = row;

  const [openConfirm, setOpenConfirm] = useState(false);

  const [openPopover, setOpenPopover] = useState(null);

  const navigate = useNavigate();

  return (
    <TableRow hover selected={selected}>
      <TableCell>
        <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
          {status}
        </Typography>
      </TableCell>
      <TableCell align="right">{fDateTime(created_at)}</TableCell>
    </TableRow>
  );
}
