import { Helmet } from 'react-helmet-async';
import { paramCase } from 'change-case';
import { useState, useEffect, useCallback } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import {
  Card,
  Table,
  Button,
  Tooltip,
  TableBody,
  Container,
  IconButton,
  TableContainer,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  MenuItem,
  Grid,
  TextField,
} from '@mui/material';
// redux rtk

// sections
import { useSnackbar } from '../../theme/sections/snackbar';
import { useSettingsContext } from '../../theme/sections/settings';
import {
  useTable,
  getComparator,
  emptyRows,
  TableNoData,
  TableSkeleton,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from '../../theme/sections/table';
import Iconify from '../../theme/sections/iconify';
import Scrollbar from '../../theme/sections/scrollbar';
import CustomBreadcrumbs from '../../theme/sections/custom-breadcrumbs';
import ConfirmDialog from '../../theme/sections/confirm-dialog';
// components
import UserBucketTableRow from 'src/components/user/UserBucketTableRow';
import { Stack } from '@mui/system';
import FormProvider, { RHFTextField, RHFSwitch, RHFSelect } from '../../theme/sections/hook-form';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
// rtk
import {
  useServicesQuery,
  useAddBucketMutation,
  useListBucketQuery,
  useDeleteBucketMutation,
  useUpdateBucketMutation,
  useBucketStatusMutation,
} from 'src/redux/services/bucketApi';
import { DatePicker } from '@mui/x-date-pickers';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'type', label: 'Type', align: 'left' },
  { id: 'service', label: 'Services', align: 'center' },
  { id: 'bucket', label: 'Paid', align: 'left' },
  { id: 'consumed', label: 'Consumed', align: 'left' },
  { id: 'balance', label: 'Balance', align: 'left' },
  { id: 'expiry', label: 'Expiry', align: 'left' },
  { id: 'created', label: 'Created', align: 'left' },
  { id: 'status', label: 'Status', align: 'left' },
  { id: '' },
];

// ----------------------------------------------------------------------

export default function UserBucketPage() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({
    defaultOrderBy: 'createdAt',
    defaultOrder: 'desc',
  });

  const { themeStretch } = useSettingsContext();

  const [tableData, setTableData] = useState([]);

  const [filterName, setFilterName] = useState('');

  const { enqueueSnackbar } = useSnackbar(); // toast

  const [edit, setEdit] = useState(false);

  const location = useLocation();
  let { name, userId } = location.state;

  // rtk
  const { data, error, isLoading, isSuccess } = useListBucketQuery(userId);
  const { data: services, isSuccess: serviceSuccess } = useServicesQuery(userId);
  const [addBucket, result] = useAddBucketMutation();
  const [updateBucket, updateResult] = useUpdateBucketMutation();
  const [deleteBucket] = useDeleteBucketMutation();
  const [updateStatus, statusResult] = useBucketStatusMutation();

  useEffect(() => {
    if (isSuccess) {
      setTableData(data);
    }
  }, [data, isSuccess]);

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(order, orderBy),
    filterName,
  });

  const dataInPage = dataFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const denseHeight = dense ? 60 : 80;

  const isFiltered = filterName !== '';

  const isNotFound = (!dataFiltered.length && !!filterName) || (isLoading && !dataFiltered.length);

  const handleFilterName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleResetFilter = () => {
    setFilterName('');
  };

  // form validation rules
  const validationSchema = Yup.object().shape({
    type: Yup.string().required('Type is required'),
    bucket: Yup.number().typeError('Amount must be numerical').required('Amount is required'),
    used: Yup.number().typeError('Consumed must be numerical').required('Consumed is required'),
    expiryDate: Yup.date().typeError('Expiry must be a date.').required('Expiry date is required'),
    bucketTypes: Yup.array()
      .of(
        Yup.object().shape({
          serviceId: Yup.string().required('Service is required'),
          rate: Yup.number().typeError('Rate must be numerical.').required('Rate is required'),
          groupRate: Yup.number()
            .typeError('Group rate must be numerical.')
            .required('Group rate is required'),
        })
      )
      .min(1, 'At least one service is required'),
  });

  // default values for useForm
  const defaultValues = {
    type: '',
    bucket: '',
    used: 0,
    expiryDate: '',
    bucketTypes: [],
  };

  // functions to build form returned by useForm() and useFieldArray() hooks
  const methods = useForm({ resolver: yupResolver(validationSchema), defaultValues });
  const { control, setValue, setError, handleSubmit, formState, clearErrors, reset } = methods;
  const { errors } = formState;

  const { fields, append, remove } = useFieldArray({
    name: 'bucketTypes',
    control,
  });

  const onSubmit = (data) => {
    data.userId = userId;

    if (edit) {
      updateBucket(data);
    } else {
      addBucket(data);
    }
  };

  useEffect(() => {
    if (result.error) {
      enqueueSnackbar(result.error.data.errors[0].message, { variant: 'error' });
      setError('afterSubmit', {
        message: result.error.data.errors[0].message,
      });
      setTimeout(() => clearErrors(), 10000);
    }
    if (result.isSuccess) {
      enqueueSnackbar('Bucket has been added successfully');
      reset();
      setOpen(false);
    }
  }, [result]);

  useEffect(() => {
    if (updateResult.error) {
      enqueueSnackbar(updateResult.error.data.errors[0].message, { variant: 'error' });
      setError('afterSubmit', {
        message: updateResult.error.data.errors[0].message,
      });
      setTimeout(() => clearErrors(), 10000);
    }
    if (updateResult.isSuccess) {
      enqueueSnackbar('Bucket has been updated successfully');
      reset();
      setEdit(false);
      setOpen(false);
    }
  }, [updateResult]);

  //dialogue
  const [open, setOpen] = useState(false);

  const handleAddBucket = () => {
    setEdit(false);
    setOpen(true);
  };

  const handleClose = () => {
    reset();
    setEdit(false);
    setOpen(false);
  };

  //add dynamic fields for bucketTypes
  const handleAdd = () => {
    append({
      serviceId: '',
      rate: '',
      groupRate: '',
    });
  };

  //remove dynamic field for member
  const handleRemove = (i) => {
    remove(i);
  };

  //set value for dynamic field for services
  const handleChange = useCallback(
    (e, i, name) => {
      setValue(`bucketTypes[${i}].` + name, e.target.value);
    },
    [setValue]
  );

  const handleDeleteRow = (id) => {
    const deleteRow = tableData.filter((row) => row.id !== id);
    setSelected([]);
    setTableData(deleteRow);

    if (page > 0) {
      if (dataInPage.length < 2) {
        setPage(page - 1);
      }
    }
    deleteBucket(id);
    enqueueSnackbar('Bucket has been deleted successfully');
  };

  const handleEditRow = (row) => {
    setEdit(true);
    setOpen(true);
    setValue('bucketId', row.id);
    setValue('type', row.type);
    setValue('bucket', row.bucket);
    setValue('used', row.used);
    setValue('expiryDate', row.expiryDate);
    row.bucketTypes.map((val, index) => {
      handleAdd();
      setValue(`bucketTypes[${index}].serviceId`, val.service_id);
      setValue(`bucketTypes[${index}].rate`, val.rate);
      setValue(`bucketTypes[${index}].groupRate`, val.group_rate);
    });
  };

  const handleStatus = (id, status) => {
    const updated = status == 1 ? 0 : 1;
    updateStatus({ bucketId: id, status: updated });
  };

  useEffect(() => {
    if (statusResult.error) {
      enqueueSnackbar(statusResult.error.data.errors[0].message, { variant: 'error' });
      setError('afterSubmit', {
        message: statusResult.error.data.errors[0].message,
      });
      setTimeout(() => clearErrors(), 10000);
    }
    if (statusResult.isSuccess) {
      enqueueSnackbar('Status has been updated successfully');
    }
  }, [statusResult]);
  return (
    <>
      <Helmet>
        <title>User Bucket | {process.env.REACT_APP_NAME} </title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'xlg'}>
        <CustomBreadcrumbs
          heading={name}
          links={[
            { name: 'Dashboard', href: '/dashboard' },
            {
              name: 'User',
              href: '/user',
            },
            { name: 'Bucket' },
          ]}
          action={
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={handleAddBucket}
            >
              Add New Bucket
            </Button>
          }
        />

        <Card>
          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction dense={dense} rowCount={tableData.length} />

            <Scrollbar>
              <Table size={dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  onSort={onSort}
                />

                <TableBody>
                  {(isLoading ? [...Array(rowsPerPage)] : dataFiltered)
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) =>
                      row ? (
                        <UserBucketTableRow
                          sx={{ border: '1px solid white' }}
                          key={row.id}
                          row={row}
                          selected={selected.includes(row.id)}
                          onSelectRow={() => onSelectRow(row.id)}
                          onDeleteRow={() => handleDeleteRow(row.id)}
                          onEditRow={() => handleEditRow(row)}
                          onViewRow={() => handleViewRow(row.id, row.name, row.description)}
                          handleStatus={() => handleStatus(row.id, row.status)}
                        />
                      ) : (
                        !isNotFound && <TableSkeleton key={index} sx={{ height: denseHeight }} />
                      )
                    )}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(page, rowsPerPage, tableData.length)}
                  />

                  <TableNoData isNotFound={isNotFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={dataFiltered.length}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            //
            dense={dense}
            onChangeDense={onChangeDense}
          />
        </Card>
      </Container>

      <Dialog fullWidth open={open} maxWidth="md">
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle sx={{ p: (theme) => theme.spacing(3, 3, 2, 3) }}>
            {edit ? 'Edit Bucket Details' : 'Add New Bucket'}
          </DialogTitle>
          <DialogContent dividers sx={{ pt: 1, pb: 0, border: 'none' }}>
            <Grid container spacing={3}>
              <RHFTextField name="bucketId" sx={{ display: 'none' }} />

              <Grid item xs={12} md={3}>
                <RHFSelect name="type" label="Type" placeholder="Select bucket type">
                  <MenuItem key="prepaid" value="prepaid">
                    Prepaid
                  </MenuItem>
                  <MenuItem key="postpaid" value="postpaid">
                    Postpaid
                  </MenuItem>
                </RHFSelect>
              </Grid>

              <Grid item xs={12} md={3}>
                <RHFTextField
                  name="bucket"
                  label="Amount"
                  onChange={(e) => setValue('bucket', e.target.value)}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <RHFTextField
                  name="used"
                  label="Consumed"
                  onChange={(e) => setValue('used', e.target.value)}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <Controller
                  name="expiryDate"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <DatePicker
                      label="Expiry Date"
                      value={field.value}
                      onChange={(newValue) => {
                        field.onChange(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>

              <Grid item container spacing={3}>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <Button
                    startIcon={<Iconify icon="eva:plus-fill" />}
                    onClick={handleAdd}
                    variant="contained"
                  >
                    Add Service
                  </Button>
                  {errors.bucketTypes && (
                    <Typography variant="body2" color="error">
                      {errors.bucketTypes.message}
                    </Typography>
                  )}
                </Grid>
                {fields.map((item, i) => (
                  <Grid item key={item.id} container spacing={2}>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                      <Controller
                        name={`bucketTypes[${i}].serviceId`}
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <RHFSelect
                            name="serviceId"
                            placeholder="Select service"
                            label={i + 1 + '. Service Type'}
                            fullWidth
                            {...field}
                            error={!!error}
                            helperText={errors.bucketTypes?.[i]?.serviceId?.message}
                          >
                            {serviceSuccess &&
                              services.map((service) => (
                                <MenuItem key={service.id} value={service.id}>
                                  {service.name}
                                </MenuItem>
                              ))}
                          </RHFSelect>
                        )}
                      />
                    </Grid>
                    <Grid item lg={3} md={3} sm={12} xs={12}>
                      <Controller
                        name={`bucketTypes[${i}].rate`}
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            label="Rate"
                            {...field}
                            fullWidth
                            error={!!error}
                            helperText={errors.bucketTypes?.[i]?.rate?.message}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item lg={3} md={3} sm={12} xs={12}>
                      <Controller
                        name={`bucketTypes[${i}].groupRate`}
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            label="Group Rate"
                            {...field}
                            fullWidth
                            error={!!error}
                            helperText={errors.bucketTypes?.[i]?.groupRate?.message}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item lg={2} md={2} sm={12} xs={12}>
                      <Button
                        onClick={() => handleRemove(i)}
                        startIcon={<Iconify icon="eva:trash-2-outline" />}
                        variant="soft"
                        color="error"
                        sx={{ my: 1 }}
                      >
                        Remove
                      </Button>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="soft" type="submit">
              Save
            </Button>
            <Button onClick={handleClose} color="error">
              Cancel
            </Button>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({ inputData, comparator, filterName }) {
  const stabilizedThis = inputData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    inputData = inputData.filter(
      (data) => data.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    );
  }

  return inputData;
}
