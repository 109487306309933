import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// @mui
import { alpha, useTheme } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
// utils
import { bgGradient } from '../../theme/utils/cssStyles';
import { fShortenNumber } from '../../theme/utils/formatNumber';
// components
import Iconify from '../../theme/sections/iconify';
import { fDate, fDateTime } from 'src/theme/utils/formatTime';
import { useStatusSessionQuery } from 'src/redux/services/sessionApi';

// ----------------------------------------------------------------------

export default function SessionWidget({ title, updated_at, user, sessionId, sx, ...other }) {
  const theme = useTheme();
  const [color, setColor] = useState('primary');
  const [icon, setIcon] = useState('solar:check-read-line-duotone');

  const {
    data,
    isLoading: loading,
    isSuccess: success,
    isError,
    isFetching,
  } = useStatusSessionQuery(sessionId, {
    pollingInterval: 10000,
  });

  useEffect(() => {
    if (success && data && data.response === 'success') {
      setColor('primary');
      setIcon('solar:check-read-line-duotone');
    }
    if (isError || (data && data.response === 'failed') || !data) {
      setColor('error');
      setIcon('icomoon-free:cross');
    }
  }, [success, isError]);

  return (
    <Card
      sx={{
        py: 2,
        boxShadow: 0,
        textAlign: 'center',
        color: theme.palette[color].lighter,
        bgcolor: theme.palette[color].darker,
        ...sx,
      }}
      {...other}
    >
      <Iconify
        icon={icon}
        sx={{
          mb: 1,
          p: 1.5,
          width: 70,
          height: 70,
          borderRadius: '40%',
          color: theme.palette[color].light,
          ...bgGradient({
            direction: '135deg',
            startColor: `${alpha(theme.palette[color].lighter, 0)} 0%`,
            endColor: `${alpha(theme.palette[color].main, 0.24)} 100%`,
          }),
        }}
      />

      <Typography variant="h5">{title}</Typography>
      <Typography variant="h6">
        <Typography variant="caption">User Name:</Typography> {user}
      </Typography>
      {isFetching ? (
        <Typography variant="body">Loading...</Typography>
      ) : (
        <Typography variant="body">
          <Typography variant="caption">Last Scanned: </Typography>
          {fDateTime(updated_at)}
        </Typography>
      )}
    </Card>
  );
}
