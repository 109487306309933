// sections
import SvgColor from '../../../theme/sections/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  dashboard: icon('ic_analytics'),
  qrcode: icon('ic_qrcode'),
  usersettings: icon('ic_usersettings'),
  adduser: icon('ic_adduser'),
  chat: icon('ic_chat'),
  app: icon('ic_app'),
  lock: icon('ic_lock'),
  count: icon('ic_count'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'Dashboard',
    items: [{ title: 'Analytics', path: '/dashboard', icon: ICONS.dashboard }],
  },
  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'Management',
    items: [
      {
        title: 'Create User',
        path: '/user/create',
        icon: ICONS.adduser,
      },
      {
        title: 'User Management',
        path: '/user/list',
        icon: ICONS.usersettings,
      },
      {
        title: 'Session Management',
        path: '/user/session',
        icon: ICONS.qrcode,
      },
    ],
  },
  //LOGS
  //----------------------------------------------------------------------
  {
    subheader: 'Logs',
    items: [
      {
        title: 'Counter Logs',
        path: '/logs/counter',
        icon: ICONS.count,
      },
      {
        title: 'Message Logs',
        path: '/logs/message',
        icon: ICONS.chat,
      },
      {
        title: 'App Logs',
        path: '/logs/app/message',
        icon: ICONS.app,
      },
    ],
  },
  // SETTINGS
  // ----------------------------------------------------------------------
  {
    subheader: 'Settings',
    items: [
      {
        title: 'Privileges',
        path: '/settings/privilege',
        icon: ICONS.lock,
      },
    ],
  },
];

export default navConfig;
