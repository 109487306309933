import { Helmet } from 'react-helmet-async';
// components
import SessionWidget from 'src/components/user/SessionWidget';
// sections
import { useSettingsContext } from '../../theme/sections/settings';
import CustomBreadcrumbs from '../../theme/sections/custom-breadcrumbs';
//mui
import { Box, Container } from '@mui/material';
//rtk
import { useActiveListQuery } from 'src/redux/services/sessionApi';
// ----------------------------------------------------------------------

export default function UserSessionPage() {
  const { themeStretch } = useSettingsContext();

  const { data: sessions, isLoading, isSuccess } = useActiveListQuery();

  return (
    <>
      <Helmet>
        <title> User Session | {process.env.REACT_APP_NAME} </title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="User Session"
          links={[
            {
              name: 'User',
              href: '/user',
            },
            {
              name: 'Session',
            },
          ]}
        />

        <Box display="grid" gap={3} gridTemplateColumns="repeat(3, 1fr)">
          {isSuccess &&
            sessions.map((session) => (
              <SessionWidget
                title={session.name}
                updated_at={session.updated_at}
                user={session.user.name}
                sessionId={session.id}
                key={session.id}
              />
            ))}
        </Box>
      </Container>
    </>
  );
}
