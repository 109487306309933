import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
// @mui
import {
  Card,
  Table,
  Button,
  Tooltip,
  TableBody,
  Container,
  IconButton,
  TableContainer,
  Divider,
  Typography,
  CardContent,
  CardActions,
  CardHeader,
  Stack,
} from '@mui/material';
// redux rtk

// sections
import { useSnackbar } from '../../../theme/sections/snackbar';
import { useSettingsContext } from '../../../theme/sections/settings';
import {
  useTable,
  getComparator,
  emptyRows,
  TableNoData,
  TableSkeleton,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from '../../../theme/sections/table';
import Iconify from '../../../theme/sections/iconify';
import Scrollbar from '../../../theme/sections/scrollbar';
import CustomBreadcrumbs from '../../../theme/sections/custom-breadcrumbs';
import ConfirmDialog from '../../../theme/sections/confirm-dialog';
// components
import {
  UserSessionTableRow,
  UserSessionTableToolbar,
} from '../../../components/whatsapp/session/list';
import Label from 'src/theme/sections/label/Label';

// rtk
import { useSessionLogQuery } from 'src/redux/services/userApi';
import { useCheckSessionMutation } from 'src/redux/services/sessionApi';
import { fDateTime } from 'src/theme/utils/formatTime';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'status', label: 'Session Status', align: 'left' },
  { id: 'created_at', label: 'Created at', align: 'right' },
];

// ----------------------------------------------------------------------

export default function UserSessionLogPage() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({
    defaultOrderBy: 'created_at',
  });

  const { themeStretch } = useSettingsContext();

  const navigate = useNavigate();

  const [tableData, setTableData] = useState([]);

  const [count, setCount] = useState([]);

  const [filterName, setFilterName] = useState('');

  const { enqueueSnackbar } = useSnackbar(); // toast

  const location = useLocation();
  let { sessionid, name } = location.state;

  // rtk
  const { data, error, isLoading, isSuccess } = useSessionLogQuery(sessionid);

  useEffect(() => {
    if (isSuccess) {
      setTableData(data.sessionStatus);
    }
  }, [data, isSuccess]);

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(order, orderBy),
    filterName,
  });

  const dataInPage = dataFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const denseHeight = dense ? 60 : 80;

  const isFiltered = filterName !== '';

  const isNotFound = (!dataFiltered.length && !!filterName) || (isLoading && !dataFiltered.length);

  const handleFilterName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleResetFilter = () => {
    setFilterName('');
  };

  const [checkSession, result] = useCheckSessionMutation();

  useEffect(() => {
    if (result.isSuccess) {
      enqueueSnackbar(
        'Response: ' +
          result.data.response +
          '\n Message: ' +
          result.data.message +
          '\n Timestamp: ' +
          fDateTime(new Date()),
        {
          variant: 'info',
          autoHideDuration: null,
          sx: { whiteSpace: 'pre-line', fontSize: 16 },
        }
      );
    }
  }, [result.data]);

  const handleCheckStatusClick = (e, sessionid) => {
    checkSession(sessionid);
  };

  const handleRestartClick = (e, sessionid) => {
    e.preventDefault();
    navigate('/whatsapp/session/restart', { state: { sessionid: sessionid } });
  };

  return (
    <>
      <Helmet>
        <title>User Session Log | {process.env.REACT_APP_NAME} </title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading={`${name} Session Log`}
          links={[
            { name: 'Dashboard', href: '/dashboard' },
            {
              name: 'User List',
              href: '/user/list',
            },
            { name: 'Session Log' },
          ]}
        />

        <Card sx={{ mb: 5 }} color="primary">
          <CardHeader
            action={
              <CardActions>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(e) => handleRestartClick(e, sessionid)}
                >
                  Restart Session
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={(e) => handleCheckStatusClick(e, sessionid)}
                >
                  Check Status
                </Button>
              </CardActions>
            }
            title={`Session ID:  ${isSuccess && data.sessionId ? data.sessionId : '-'}`}
            titleTypographyProps={{ variant: 'h4' }}
            subheader={
              <Label color="primary" sx={{ ml: 2, mb: 2, fontSize: 14 }}>
                Token: &nbsp; {isSuccess && data.token ? data.token : '-'}
              </Label>
            }
          />
          <CardContent>
            {/* <Typography variant="h3" sx={{ mb: 2, color: 'primary.main' }}></Typography>
            <Typography variant="subtitle2">
              Token:
              <Label color="primary" sx={{ ml: 2, mb: 2 }}>
                <code>{isSuccess && data.token ? data.token : '-'}</code>
              </Label>
            </Typography> */}
            <Divider sx={{ borderStyle: 'dashed' }} />
            <Scrollbar>
              <Stack
                direction="row"
                divider={<Divider orientation="vertical" flexItem sx={{ borderStyle: 'dashed' }} />}
                sx={{ py: 2 }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ width: 1, minWidth: 200 }}
                >
                  <Stack spacing={0.5} sx={{ ml: 2 }}>
                    <Typography variant="h5" align="center">
                      Name
                    </Typography>
                    <Typography variant="subtitle2" align="center">
                      {isSuccess && data.sessionName ? data.sessionName : '-'}
                    </Typography>
                  </Stack>
                </Stack>

                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ width: 1, minWidth: 200 }}
                >
                  <Stack spacing={0.5} sx={{ ml: 2 }}>
                    <Typography variant="h5" align="center">
                      Account Type
                    </Typography>
                    <Typography variant="subtitle2" align="center">
                      {isSuccess && data.accountType ? data.accountType : '-'}
                    </Typography>
                  </Stack>
                </Stack>

                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ width: 1, minWidth: 200 }}
                >
                  <Stack spacing={0.5} sx={{ ml: 2 }}>
                    <Typography variant="h5" align="center">
                      Account Status
                    </Typography>
                    <Typography variant="subtitle2" align="center">
                      {isSuccess && data.status ? data.status : '-'}
                    </Typography>
                  </Stack>
                </Stack>

                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ width: 1, minWidth: 200 }}
                >
                  <Stack spacing={0.5} sx={{ ml: 2 }}>
                    <Typography variant="h5" align="center">
                      Engine URL
                    </Typography>
                    <Typography variant="subtitle2" align="center">
                      {isSuccess && data.engineUrl ? data.engineUrl : '-'}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Scrollbar>

            <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
              <TableSelectedAction
                dense={dense}
                numSelected={selected.length}
                rowCount={tableData.length}
              />

              <Scrollbar>
                <Table size={dense ? 'small' : 'medium'}>
                  <TableHeadCustom
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={tableData.length}
                    numSelected={selected.length}
                    onSort={onSort}
                  />

                  <TableBody>
                    {(isLoading ? [...Array(rowsPerPage)] : dataFiltered)
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) =>
                        row ? (
                          <UserSessionTableRow
                            key={index}
                            row={row}
                            selected={selected.includes(row.id)}
                          />
                        ) : (
                          !isNotFound && <TableSkeleton key={index} sx={{ height: denseHeight }} />
                        )
                      )}

                    <TableEmptyRows
                      height={denseHeight}
                      emptyRows={emptyRows(page, rowsPerPage, tableData.length)}
                    />

                    <TableNoData isNotFound={isNotFound} />
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>

            <TablePaginationCustom
              count={dataFiltered.length}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
              //
              dense={dense}
              onChangeDense={onChangeDense}
            />
          </CardContent>
        </Card>
      </Container>
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({ inputData, comparator, filterName }) {
  const stabilizedThis = inputData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    inputData = inputData.filter(
      (data) => data.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    );
  }

  return inputData;
}
