// react
import { Navigate, useRoutes } from 'react-router';
// redux
import { useSelector } from 'react-redux';
//layout
import DashboardLayout from './layouts/dashboard';
//auth
import LoginPage from './pages/auth/LoginPage';
//dashboard
import AnalyticalDashboardPage from './pages/dashboard/AnalyticalDashboardPage';
//user
import CreateUserPage from './pages/user/CreateUserPage';
import ListUserPage from './pages/user/ListUserPage';
import UserSessionPage from './pages/user/UserSessionPage';
import UserBucketPage from './pages/user/UserBucketPage';
// WHATSAPP
//session
import CreateSessionPage from './pages/whatsapp/session/CreateSessionPage';
import RestartSession from './components/whatsapp/session/RestartSession';
import UserSessionLogPage from './pages/whatsapp/session/UserSessionLogPage';
//Settings
import PrivilegePage from './pages/settings/PrivilegePage';
//Logs
import CounterLogPage from './pages/report/CounterLogPage';
import AppMessageLogPage from './pages/report/AppMessageLogPage';
import MessageLogPage from './pages/report/MessageLogPage';

// ----------------------------------------------------------------------

export default function Router() {
  // checks if logged in
  const { user } = useSelector((state) => state.auth);
  const element = user ? <DashboardLayout /> : <Navigate to="/login" />;
  // const element = <DashboardLayout />;

  const routes = useRoutes([
    {
      path: '/dashboard',
      element: element,
      children: [{ index: true, element: <AnalyticalDashboardPage /> }],
    },
    {
      path: '/user',
      element: element,
      children: [
        { path: 'create', element: <CreateUserPage /> },
        { path: 'list', element: <ListUserPage /> },
        { path: 'session', element: <UserSessionPage /> },
        { path: 'bucket', element: <UserBucketPage /> },
      ],
    },
    {
      path: '/whatsapp',
      element: element,
      children: [
        { path: 'session/create', element: <CreateSessionPage /> },
        {
          path: 'session/restart',
          element: <RestartSession />,
        },
        {
          path: 'user/session/log',
          element: <UserSessionLogPage />,
        },
      ],
    },
    {
      path: '/logs',
      element: element,
      children: [
        { path: 'counter', element: <CounterLogPage /> },
        { path: 'message', element: <MessageLogPage /> },
        { path: 'app/message', element: <AppMessageLogPage /> },
      ],
    },
    {
      path: '/settings',
      element: element,
      children: [{ path: 'privilege', element: <PrivilegePage /> }],
    },
    {
      path: '/',
      element: <LoginPage />,
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
  ]);

  return routes;
}
