import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL + '/users',
    //set header
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.user.accessToken;
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['User'],
  endpoints: (builder) => ({
    // Get all users
    users: builder.query({
      query: () => '/',
      providesTags: ['User'],
    }),

    // create user
    createUser: builder.mutation({
      query: (user) => ({
        url: '/create',
        method: 'POST',
        body: user,
      }),
      invalidatesTags: ['User'],
    }),

    // search user
    searchUser: builder.mutation({
      query: (data) => ({
        url: '/search',
        method: 'POST',
        body: data,
      }),
    }),

    // Get specific user session log
    sessionLog: builder.query({
      query: (sessionId) => ({
        url: `/session/${sessionId}`,
      }),
    }),

    // delete member
    // deleteMember: builder.mutation({
    //   query: (id) => ({
    //     url: `/${id}`,
    //     method: 'DELETE',
    //   }),
    //   invalidatesTags: ['Member'],
    // }),

    // update member
    // updateMember: builder.mutation({
    //   query: ({ id, ...rest }) => ({
    //     url: `/${id}`,
    //     method: 'PUT',
    //     body: rest,
    //   }),
    //   invalidatesTags: ['Member'],
    // }),
  }),
});

export const {
  useUsersQuery,
  useSessionLogQuery,
  useCreateUserMutation,
  //useUpdateMemberMutation,
  //useDeleteMemberMutation,
} = userApi;
