import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const bucketApi = createApi({
  reducerPath: 'bucketApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    //set header
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.user.accessToken;
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Buckets'],
  endpoints: (builder) => ({
    // Get all services
    services: builder.query({
      query: () => '/services',
    }),

    // list user buckets
    listBucket: builder.query({
      query: (userId) => `users/bucket/${userId}`,
      providesTags: ['Buckets'],
    }),

    // add bucket
    addBucket: builder.mutation({
      query: (data) => ({
        url: '/users/bucket',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Buckets'],
    }),

    // update bucket status
    bucketStatus: builder.mutation({
      query: (data) => ({
        url: '/users/bucket/status',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Buckets'],
    }),

    // update bucket details
    updateBucket: builder.mutation({
      query: (data) => ({
        url: `/users/bucket/details/${data.bucketId}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Buckets'],
    }),

    //delete bucket
    deleteBucket: builder.mutation({
      query: (id) => ({
        url: `users/bucket/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Buckets'],
    }),

    //delete bucket
    deleteBucketType: builder.mutation({
      query: (id) => ({
        url: `users/bucket/type/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Buckets'],
    }),
  }),
});

export const {
  useServicesQuery,
  useListBucketQuery,
  useAddBucketMutation,
  useBucketStatusMutation,
  useUpdateBucketMutation,
  useDeleteBucketMutation,
  useDeleteBucketTypeMutation,
} = bucketApi;
