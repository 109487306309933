import { Card, Container, Divider, Pagination, TablePagination, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
// sections
import { useSettingsContext } from '../../theme/sections/settings';
import CustomBreadcrumbs from '../../theme/sections/custom-breadcrumbs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// axios
import axios from '../../theme/utils/axios';
//components
import AppMessageLogTable from 'src/components/report/AppMessageLogTable';

// ----------------------------------------------------------------------

export default function AppMessageLogPage() {
  const { themeStretch } = useSettingsContext();

  // user details
  const { user } = useSelector((state) => state.auth);
  const config = {
    headers: {
      Authorization: `Bearer ${user.accessToken}`,
    },
  };

  const [page, setPage] = useState(1);
  const [tableData, setTableData] = useState([]);
  const [metaData, setMetaData] = useState([]);

  //on Load
  useEffect(() => {
    callApi(page);
    console.log(1);
  }, []);

  // set page on change
  const handleChangePage = async (event, number) => {
    event.preventDefault();
    setPage(number);
    callApi(number);
  };

  const callApi = async (page) => {
    try {
      const response = await axios.get(`/logs/application/?page=${page}`, config);
      if (response) {
        setMetaData(response.data.meta);
        setTableData(response.data.logs);
        //console.log(response.data.logs);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Application Message Logs | {process.env.REACT_APP_NAME} </title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'xlg'}>
        <CustomBreadcrumbs
          heading="App Message Logs"
          links={[
            { name: 'Dashboard', href: '/dashboard' },
            {
              name: 'Logs',
              href: '/logs',
            },
            { name: 'App Message' },
          ]}
        />

        <Card>
          <AppMessageLogTable tableData={tableData} page={page} />

          <Divider />

          <Pagination
            sx={{ p: 2 }}
            component="div"
            count={metaData.last_page}
            page={page}
            onChange={handleChangePage}
          />
        </Card>
      </Container>
    </>
  );
}
