import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
// form
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, Stack, Typography, IconButton, Divider, CardHeader } from '@mui/material';
// utils
import { fData } from '../../../theme/utils/formatNumber';
// sections
import Label from '../../../theme/sections/label';
import { useSnackbar } from '../../../theme/sections/snackbar';
import FormProvider from '../../../theme/sections/hook-form';
import Iconify from 'src/theme/sections/iconify/Iconify';
// react
import { useLocation, useNavigate } from 'react-router';
// rtk
import { useStartSessionMutation } from 'src/redux/services/sessionApi';

// ----------------------------------------------------------------------

export default function RestartSession() {
  const location = useLocation();
  let { sessionid } = location.state;
  const [heading, setHeading] = useState('Loading QR code...');
  const { enqueueSnackbar } = useSnackbar();

  const methods = useForm();
  const {
    reset,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  //rtk
  const [startSession, result] = useStartSessionMutation();

  useEffect(() => {
    startSession(sessionid);
  }, []);

  const navigate = useNavigate();
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    if (result.error) {
      enqueueSnackbar(
        result.error.data?.errors?.message ? result.error.data?.message : result.error.error,
        { variant: 'error' }
      );
    }
    if (result.isSuccess) {
      setHeading('Scan the below QR code to get started');
      enqueueSnackbar('Scan QR code to start.');
      setCounter(60);
      const timer = setTimeout(() => navigate('/user/list'), 60000);
    }
  }, [result]);

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  return (
    <FormProvider methods={methods}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Scan QR code" subheader={heading} />
            {result.isSuccess && (
              <Typography variant="subtitle2" sx={{ p: 2 }}>
                {`you will be redirected after: ${counter} seconds`}
              </Typography>
            )}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 5,
                backgroundColor: 'primary.main',
              }}
            >
              {result.isLoading && (
                <img
                  src={require('../../../assets/qr-code-animation.gif')}
                  alt="loading..."
                  width="200px"
                />
              )}
              {result.isSuccess && <img src={result.data.qrCode} alt="qrcode" width="300px" />}
            </Box>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
