import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useCallback } from 'react';
import Label from 'src/theme/sections/label/Label';
//utils
import { fDateTime } from 'src/theme/utils/formatTime';

export default function AppMessageLogTable({ tableData, page }) {
  return (
    <TableContainer component={Paper} sx={{ p: 2 }} key={page}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Event</TableCell>
            <TableCell>Data</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Created At</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.map((row) => (
            <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell>{row.event}</TableCell>
              <TableCell>{row.data}</TableCell>
              <TableCell>
                <Label variant="soft" color="default">
                  {row.type}
                </Label>
              </TableCell>
              <TableCell>{fDateTime(row.createdAt)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
