import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Grid,
  Box,
  Stack,
  Divider,
  IconButton,
} from '@mui/material';

import { useState } from 'react';
import Label from 'src/theme/sections/label/Label';
//utils
import { fDateTime } from 'src/theme/utils/formatTime';
import axios from '../../theme/utils/axios';
import Iconify from 'src/theme/sections/iconify/Iconify';

export default function MessageLogTable({ tableData, page, loading, config }) {
  const statusLabel = (status) => {
    switch (status) {
      case 'chat':
        return (
          <Label variant="soft" color="primary">
            CHAT
          </Label>
        );
      case 'Text':
        return (
          <Label variant="soft" color="secondary">
            TEXT
          </Label>
        );
      case 'Media':
        return (
          <Label variant="soft" color="warning">
            MEDIA
          </Label>
        );

      case 'Location':
        return (
          <Label variant="soft" color="info">
            LOCATION
          </Label>
        );
    }
  };

  const [loadContent, setLoadContent] = useState(false);
  const [open, setOpen] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [data, setData] = useState([]);

  const handleViewRow = async (id) => {
    event.preventDefault();
    setLoadContent(true);
    setShowMore(false);
    setOpen(true);
    try {
      const response = await axios.get(`/logs/message/${id}`, config);
      if (response) {
        setLoadContent(false);
        setData(response.data.log);
        //console.log(response.data.log);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <TableContainer component={Paper} sx={{ p: 2 }} key={page}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>User</TableCell>
              <TableCell>Sender</TableCell>
              <TableCell>Recipient</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Channel</TableCell>
              <TableCell>Message</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Details</TableCell>
              <TableCell>Created At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow key={1} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell colSpan={8} align="center">
                  <Typography variant="h4">Loading...</Typography>
                </TableCell>
              </TableRow>
            ) : (
              tableData.map((row) => (
                <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell>
                    <Typography variant="subtitle2" color="primary.main">
                      {row.user && row.user.name}
                    </Typography>
                  </TableCell>
                  <TableCell>{row.sender ? row.sender : '---'}</TableCell>
                  <TableCell>{row.receipient}</TableCell>
                  <TableCell>
                    {row.type ? (
                      statusLabel(row.type)
                    ) : (
                      <Label variant="soft" color="default">
                        not defined
                      </Label>
                    )}
                  </TableCell>
                  <TableCell>
                    {row.self == 'OUT' ? (
                      <Label color="error">{row.self}</Label>
                    ) : (
                      <Label color="primary">{row.self}</Label>
                    )}
                  </TableCell>
                  <TableCell>
                    {row.message && row.message.length > 30
                      ? `${row.message.substring(0, 30)}...`
                      : row.message}
                  </TableCell>
                  <TableCell>
                    <Label
                      variant="soft"
                      color={
                        (row.status === 'Sent' && 'success') ||
                        (row.status === 'Delivered' && 'success') ||
                        (row.status === 'Played' && 'success') ||
                        (row.status === 'Seen' && 'success') ||
                        (row.status === 'Failed' && 'error') ||
                        'warning'
                      }
                    >
                      {row.status}
                    </Label>
                  </TableCell>
                  <TableCell>
                    <Button
                      color="primary"
                      variant="contained"
                      size="small"
                      onClick={() => handleViewRow(row.id)}
                    >
                      Details
                    </Button>
                  </TableCell>
                  <TableCell>{fDateTime(row.created_at)}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog fullWidth open={open} maxWidth="md">
        <DialogTitle sx={{ p: (theme) => theme.spacing(3, 3, 2, 3) }}>
          Message Details{' '}
          {open ? (
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Iconify icon="mdi:close-outline" width={24} />
            </IconButton>
          ) : null}
        </DialogTitle>
        <Divider sx={{ borderStyle: 'dashed' }} />
        <DialogContent dividers sx={{ pt: 1, pb: 0, border: 'none' }}>
          {loadContent ? (
            <Typography variant="h6" align="center">
              Loading ...
            </Typography>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} lg={5}>
                <Box>
                  <Typography variant="h6"> User: {data.user}</Typography>

                  <Typography variant="h6"> Session: {data.session}</Typography>
                  <Typography variant="subtitle2">
                    Transactional ID : &nbsp;
                    <Label variant="soft" color="success" sx={{ mb: 1 }}>
                      {data.transactionalId}
                    </Label>
                  </Typography>
                  <Typography variant="subtitle2">
                    Platoform : &nbsp;
                    <Label variant="soft" color="warning" sx={{ mb: 1 }}>
                      {data.platform}
                    </Label>
                  </Typography>
                </Box>
              </Grid>
              {/* <Grid item xs={12} sm={12} lg={6}>
              <Timeline>
                {data.statuses &&
                  data.statuses.map((status, index) => (
                    <TimelineItem key={index}>
                      <TimelineOppositeContent>
                        <Typography sx={{ color: 'text.secondary' }} variant="subtitle">
                          {status.status}
                        </Typography>
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineDot
                          color={
                            (status.status === 'Seen' && 'success') ||
                            (status.status === 'Sent' && 'info') ||
                            (status.status === 'Received' && 'warning') ||
                            'default'
                          }
                        />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Typography variant="body2"> {status.timestamp}</Typography>
                      </TimelineContent>
                    </TimelineItem>
                  ))}
              </Timeline>
            </Grid> */}
              <Grid item xs={12} sm={6} lg={4}>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Sender
                  </Typography>
                  <Typography variant="body2">{data.sender}</Typography>
                </Stack>

                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Recipient
                  </Typography>
                  <Typography variant="body2">{data.receipient}</Typography>
                </Stack>

                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Recipient Type
                  </Typography>
                  <Typography variant="body2">{data.receipientType}</Typography>
                </Stack>

                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Message Type
                  </Typography>
                  <Typography variant="body2">{data.type}</Typography>
                </Stack>

                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    No. of Members
                  </Typography>
                  <Typography variant="body2">{data.members}</Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Last Status
                  </Typography>
                  <Typography variant="body2">{data.status}</Typography>
                </Stack>

                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Attempts
                  </Typography>
                  <Typography variant="body2">{data.attempts}</Typography>
                </Stack>

                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Local Log
                  </Typography>
                  <Typography variant="body2">{data.localLog}</Typography>
                </Stack>

                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Channel
                  </Typography>
                  <Typography variant="body2">{data.self}</Typography>
                </Stack>

                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Reaction
                  </Typography>
                  <Typography variant="body2">{data.reaction}</Typography>
                </Stack>
              </Grid>

              <Grid item lg={12}>
                <Stack
                  direction="row"
                  divider={
                    <Divider orientation="vertical" flexItem sx={{ borderStyle: 'dashed' }} />
                  }
                  sx={{ py: 2 }}
                >
                  {data.statuses &&
                    data.statuses.map((status, index) => (
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ width: 1, minWidth: 120 }}
                      >
                        <Stack spacing={0.5} sx={{ ml: 2 }}>
                          <Typography
                            variant="h5"
                            align="center"
                            color={
                              (status.status === 'Seen' && 'primary') ||
                              (status.status === 'Sent' && 'secondary') ||
                              (status.status === 'Received' && 'warning.main') ||
                              'default'
                            }
                          >
                            {status.status}
                          </Typography>
                          <Typography variant="subtitle2" align="center">
                            {status.timestamp}
                          </Typography>
                        </Stack>
                      </Stack>
                    ))}
                </Stack>
              </Grid>
              <Grid item lg={12} sx={{ mb: 5, wordWrap: 'break-word' }}>
                {data.message && (
                  <Typography variant="subtitle2">
                    Message:
                    <Typography variant="body2">
                      {data.message.length > 250 && showMore
                        ? data.message
                        : `${data.message.substring(0, 250)}`}
                    </Typography>
                    {data.message?.length > 250 && (
                      <Button
                        sx={{ mt: 1 }}
                        size="small"
                        variant="contained"
                        color="success"
                        onClick={() => setShowMore(!showMore)}
                      >
                        {showMore == true ? 'Show less' : 'Show more'}
                      </Button>
                    )}
                  </Typography>
                )}
                {data.message == null && <Typography variant="body2">No Message</Typography>}
              </Grid>
            </Grid>
          )}
        </DialogContent>
        {/* <DialogActions>
          <Button variant="soft" onClick={handleClose}>
            Close
          </Button>
        </DialogActions> */}
      </Dialog>
    </>
  );
}
