import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import { userApi } from '../services/userApi';
import { bucketApi } from '../services/bucketApi';
import { sessionApi } from '../services/sessionApi';
import { dashboardApi } from '../services/dashboardApi';
import { privilegeApi } from '../services/privilegeApi';
export const store = configureStore({
  reducer: {
    auth: authReducer,
    [userApi.reducerPath]: userApi.reducer,
    [bucketApi.reducerPath]: bucketApi.reducer,
    [sessionApi.reducerPath]: sessionApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [privilegeApi.reducerPath]: privilegeApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      userApi.middleware,
      bucketApi.middleware,
      sessionApi.middleware,
      dashboardApi.middleware,
      privilegeApi.middleware
    ),
});
