import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
// form
import { useFieldArray, useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  Grid,
  Typography,
  Divider,
  Alert,
  Paper,
  Autocomplete,
  TextField,
  createFilterOptions,
} from '@mui/material';
// sections
import { useSnackbar } from '../../theme/sections/snackbar';
import FormProvider, { RHFSwitch } from '../../theme/sections/hook-form';
import {
  usePermissionsQuery,
  useSaveUserPermissionMutation,
  useUserPermissionMutation,
} from 'src/redux/services/privilegeApi';
import { useUsersQuery } from 'src/redux/services/userApi';
import Label from 'src/theme/sections/label';

export default function PrivilegeForm() {
  const { enqueueSnackbar } = useSnackbar();
  const filter = createFilterOptions();

  const { data: users, isSuccess: isUsers } = useUsersQuery();
  const { data: permissionData, isSuccess: isPermissionData } = usePermissionsQuery();
  const [hasPermissions, result] = useUserPermissionMutation();
  const [savePermissions, savePermissionsResult] = useSaveUserPermissionMutation();

  const validationSchema = Yup.object().shape({
    user: Yup.string().required('User is required'),
  });

  const defaultValues = {
    user: '',
    toggle: false,
  };

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });
  const { control, setValue, getValues, setError, handleSubmit, formState, clearErrors, reset } =
    methods;
  const { errors } = formState;

  const handleUserSelect = (userId) => {
    if (userId != null) {
      hasPermissions(userId);
    }
  };

  useEffect(() => {
    if (result.isSuccess) {
      setValue('toggle', false);
      permissionData.forEach((permission) => {
        setValue(permission.id.toString(), false);
      });

      const permissions = result.data[0]?.hasPermission;
      console.log(permissions);
      if (permissions) {
        permissions.forEach((permission) => {
          setValue(permission.id.toString(), true);
        });
      }
    }
  }, [result]);

  useEffect(() => {
    console.log(users);
  }, [isUsers]);

  const handleToggleAllPermissions = (e) => {
    const isChecked = e.target.checked;
    setValue('toggle', isChecked);
    permissionData.forEach((permission) => {
      setValue(permission.id.toString(), isChecked);
    });
  };

  const onSubmit = async (data) => {
    const permissions = permissionData
      .map((permission) => ({
        id: permission.id,
        checked: data[permission.id.toString()] ? 1 : 0,
      }))
      .filter((permission) => permission.checked === 1);

    const submissionData = {
      user: data.user,
      permissions,
    };

    //console.log(submissionData);
    savePermissions(submissionData);
  };

  useEffect(() => {
    if (savePermissionsResult.error) {
      //console.log(result.error.data.errors[0].message);
      enqueueSnackbar(
        savePermissionsResult.error.data?.errors?.message
          ? savePermissionsResult.error.data?.message
          : savePermissionsResult.error.error,
        { variant: 'error' }
      );
      setError('afterSubmit', {
        message: savePermissionsResult.error.data?.message
          ? savePermissionsResult.error.data?.message
          : savePermissionsResult.error.error,
      });
      setTimeout(() => clearErrors(), 10000);
    }
    if (savePermissionsResult.isSuccess) {
      enqueueSnackbar('User privileges has been updated successfully');
    }
  }, [savePermissionsResult]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Card sx={{ p: 5 }}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={8} lg={8} xl={9}>
            {/* {isRoles && (
              <RHFSelect
                name="role"
                label="Select Role"
                onChange={(event) => {
                  const selectedRoleId = event.target.value;
                  setValue('role', selectedRoleId);
                  handleRoleSelect(selectedRoleId);
                }}
              >
                {roles.map((role) => (
                  <MenuItem key={role.id} value={role.id}>
                    {role.name}
                  </MenuItem>
                ))}
              </RHFSelect>
            )} */}
            {isUsers && (
              <Autocomplete
                onChange={(event, newValue) => {
                  if (newValue) {
                    setValue('user', newValue.id);
                    handleUserSelect(newValue.id);
                  } else {
                    setValue('user', null);
                    handleUserSelect(null);
                  }
                }}
                fullWidth
                autoHighlight
                options={users}
                getOptionLabel={(option) => option.name}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  const { inputValue } = params;

                  // Custom filter logic
                  const inputValueLowerCase = inputValue.toLowerCase();
                  return options.filter(
                    (option) =>
                      option.name.toLowerCase().includes(inputValueLowerCase) ||
                      option.email.toLowerCase().includes(inputValueLowerCase) ||
                      option.number.toLowerCase().includes(inputValueLowerCase)
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    name="user"
                    {...params}
                    label="Search a user"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
                renderOption={(props, option) => {
                  if (!option.name) {
                    return null;
                  }

                  return (
                    <div {...props} key={option.name}>
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="subtitle2" color="primary">
                          {option.name}
                        </Typography>

                        <Box>
                          <Typography variant="caption">
                            {option.roles?.name} | {option.number} | {option.email}
                          </Typography>
                        </Box>
                      </Box>
                    </div>
                  );
                }}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
            <RHFSwitch
              name="toggle"
              label={<Typography variant="subtitle2">Toggle All Permissions</Typography>}
              checked={!!getValues('toggle')}
              onClick={(event) => handleToggleAllPermissions(event)}
              sx={{ mt: 1 }}
            />
          </Grid>

          {isPermissionData &&
            permissionData.map((permission) => (
              <Grid item xs={12} sm={6} md={4} lg={4} xl={3} key={permission.id}>
                <Paper variant="outlined" sx={{ py: 2.5, textAlign: 'center' }}>
                  <RHFSwitch
                    name={permission.id.toString()}
                    label={<Typography variant="subtitle2">{permission.name}</Typography>}
                    checked={!!getValues(permission.id.toString())}
                    value={permission.id}
                    onChange={(event) => setValue(permission.id.toString(), event.target.checked)}
                  />
                </Paper>
              </Grid>
            ))}

          <Grid item xs={12}>
            <Divider />
            <LoadingButton type="submit" variant="contained" size="large" sx={{ mt: 3 }}>
              Save Permissions
            </LoadingButton>
          </Grid>
        </Grid>
      </Card>
    </FormProvider>
  );
}
