import PropTypes from 'prop-types';
import { Stack, InputAdornment, TextField, MenuItem, Button, Grid } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
// sections
import Iconify from 'src/theme/sections/iconify';

// ----------------------------------------------------------------------

//const INPUT_WIDTH = 160;

CounterLogFilter.propTypes = {
  optionsUser: PropTypes.arrayOf(PropTypes.string),
  filterUser: PropTypes.string,
  filterStartDate: PropTypes.instanceOf(Date),
  filterEndDate: PropTypes.instanceOf(Date),
  onFilterUser: PropTypes.func,
  onFilterStartDate: PropTypes.func,
  onFilterEndDate: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default function CounterLogFilter({
  optionsUser,
  filterUser,
  filterStartDate,
  filterEndDate,
  onFilterUser,
  onFilterStartDate,
  onFilterEndDate,
  onSubmit,
}) {
  return (
    <Grid container spacing={2} sx={{ px: 2.5, py: 3 }}>
      <Grid item xs={12} md={3} lg={3}>
        <DatePicker
          label="Start Date *"
          value={filterStartDate}
          onChange={onFilterStartDate}
          renderInput={(params) => <TextField {...params} fullWidth />}
        />
      </Grid>

      <Grid item xs={12} md={3} lg={3}>
        <DatePicker
          label="End date *"
          value={filterEndDate}
          onChange={onFilterEndDate}
          renderInput={(params) => <TextField {...params} fullWidth />}
        />
      </Grid>

      <Grid item xs={12} md={3} lg={3}>
        <TextField
          fullWidth
          select
          label="Users"
          value={filterUser}
          onChange={onFilterUser}
          SelectProps={{
            MenuProps: {
              PaperProps: {
                sx: { maxHeight: 220 },
              },
            },
          }}
        >
          <MenuItem
            selected
            key="0"
            value="all"
            sx={{
              mx: 1,
              borderRadius: 0.75,
              typography: 'body2',
              textTransform: 'capitalize',
            }}
          >
            All Users
          </MenuItem>
          {optionsUser.map((option) => (
            <MenuItem
              key={option.id}
              value={option.id}
              sx={{
                mx: 1,
                borderRadius: 0.75,
                typography: 'body2',
                textTransform: 'capitalize',
              }}
            >
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12} md={3} lg={3}>
        {/* {isFiltered && (
          <Button
            size="large"
            variant="soft"
            color="error"
            sx={{ flexShrink: 0 }}
            onClick={onResetFilter}
            startIcon={<Iconify icon="eva:trash-2-outline" />}
          >
            Clear Filters
          </Button>
        )} */}

        <Button
          color="primary"
          size="large"
          variant="soft"
          sx={{ mt: 0.5 }}
          onClick={onSubmit}
          startIcon={<Iconify icon="mingcute:counter-line" />}
        >
          Count Logs
        </Button>

        {/* <Button variant="soft" color="warning" size="large" onClick={exportToCSV} sx={{ ml: 1 }}>
          <Iconify icon="mdi:microsoft-excel" /> &nbsp;{' '}
          {downloading ? 'processing..' : 'Export to excel'}
        </Button> */}
      </Grid>
    </Grid>
  );
}
