import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
//mui
import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
// sections
import { useSettingsContext } from '../../theme/sections/settings';
import CustomBreadcrumbs from '../../theme/sections/custom-breadcrumbs';
import { useSnackbar } from 'src/theme/sections/snackbar';
import axios from 'src/theme/utils/axios';
import { fDateYMD } from 'src/theme/utils/formatTime';
//redux
import { useSelector } from 'react-redux';
//components
import CounterLogFilter from 'src/components/report/CounterLogFilter';
import Iconify from 'src/theme/sections/iconify/Iconify';

// ----------------------------------------------------------------------

export default function CounterLogPage() {
  const { themeStretch } = useSettingsContext();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useSelector((state) => state.auth);
  const config = {
    headers: {
      Authorization: `Bearer ${user.accessToken}`,
    },
  };

  const [users, setUsers] = useState([]);

  const [filterStartDate, setFilterStartDate] = useState(new Date());

  const [filterEndDate, setFilterEndDate] = useState(new Date());

  const [filterUser, setFilterUser] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState([]);

  const [services, setServices] = useState([]);

  useEffect(() => {
    axios
      .get('users', config)
      .then((response) => {
        const userData = response.data;
        setUsers(userData);
      })
      .catch((error) => {
        console.error('Error fetching users:', error);
      });

    axios
      .get('services', config)
      .then((response) => {
        const serviceData = response.data;
        setServices(serviceData);
      })
      .catch((error) => {
        console.error('Error fetching services:', error);
      });
  }, []);

  const handleFilterUser = (event) => {
    setFilterUser(event.target.value);
  };

  //on submit filter
  const handleOnSubmit = async () => {
    const payload = {};
    setIsLoading(true);
    if (filterStartDate === null || filterEndDate === null) {
      enqueueSnackbar('Please select date range', { variant: 'error' });
    } else {
      payload.dateFrom = fDateYMD(filterStartDate);
      payload.dateTo = fDateYMD(filterEndDate);
      if (filterUser !== '' && filterUser !== 'all') payload.userId = filterUser;

      const response = await axios.post('logs/counter', payload, config);
      if (response.data.logs) {
        setIsLoading(false);
        //console.log(response.data.logs);
        setData(response.data.logs);
      }
    }
  };

  const Percentage = (value, total) => {
    return Number((value / total) * 100).toFixed();
  };
  return (
    <>
      <Helmet>
        <title>Counter Logs | {process.env.REACT_APP_NAME} </title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Counter Logs"
          links={[
            { name: 'Dashboard', href: '/dashboard' },
            {
              name: 'Logs',
              href: '/logs',
            },
            { name: 'Counter' },
          ]}
        />

        <Grid container spacing={2}>
          <Grid item xs="12">
            <Card>
              <CounterLogFilter
                optionsUser={users}
                filterUser={filterUser}
                filterStartDate={filterStartDate}
                filterEndDate={filterEndDate}
                onFilterUser={handleFilterUser}
                onFilterStartDate={(newValue) => {
                  setFilterStartDate(newValue);
                }}
                onFilterEndDate={(newValue) => {
                  setFilterEndDate(newValue);
                }}
                onSubmit={handleOnSubmit}
              />
            </Card>
          </Grid>

          {data && data.length > 0 ? (
            data.map((datum, dataIndex) => (
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <CardHeader
                      title={
                        <Typography variant="h4" align="center" color="primary">
                          {datum.user_name}
                        </Typography>
                      }
                      sx={{ padding: 0 }}
                    />
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        flexGrow={1}
                        sx={{ minWidth: 120 }}
                      >
                        <Typography variant="h6" noWrap>
                          All Services
                        </Typography>
                      </Stack>

                      <Stack direction="row" alignItems="center" sx={{ minWidth: 80 }}>
                        <Iconify
                          width={14}
                          icon="mdi:user"
                          sx={{ mr: 0.5, color: 'text.disabled' }}
                        />
                        <Typography variant="subtitle2">Individual</Typography>
                      </Stack>

                      <Stack direction="row" alignItems="center" sx={{ minWidth: 80 }}>
                        <Iconify
                          icon="fa6-solid:users"
                          width={14}
                          sx={{ mr: 0.5, color: 'text.disabled' }}
                        />
                        <Typography variant="subtitle2">Group</Typography>
                      </Stack>

                      <Stack direction="row" alignItems="center" sx={{ minWidth: 80 }}>
                        <Typography variant="subtitle2">Total</Typography>
                      </Stack>
                    </Stack>
                    <Divider sx={{ borderBottomWidth: '3px' }} />
                    {services.map((service, serviceIndex) => {
                      const datumService = datum.services.find((d) => d.name === service.name);
                      return (
                        <>
                          <Stack
                            key={`${dataIndex}-${serviceIndex}`}
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              flexGrow={1}
                              sx={{ minWidth: 120 }}
                            >
                              <Iconify
                                icon={`mdi:${service.name.toLowerCase()}`}
                                color="primary.main"
                                sx={{ borderRadius: 0.65, width: 28, mr: 1 }}
                              />
                              <Typography variant="h6" noWrap>
                                {service.name}
                              </Typography>
                            </Stack>

                            <Stack direction="row" alignItems="center" sx={{ minWidth: 80 }}>
                              <Iconify
                                width={14}
                                icon="mdi:user"
                                sx={{ mr: 0.5, color: 'text.disabled' }}
                              />
                              <Typography variant="subtitle2">
                                {datumService ? datumService.individual_sum : 0}
                              </Typography>
                            </Stack>

                            <Stack direction="row" alignItems="center" sx={{ minWidth: 80 }}>
                              <Iconify
                                icon="fa6-solid:users"
                                width={14}
                                sx={{ mr: 0.5, color: 'text.disabled' }}
                              />
                              <Typography variant="subtitle2">
                                {datumService ? datumService.group_sum : 0}
                              </Typography>
                            </Stack>

                            <Stack direction="row" alignItems="center" sx={{ minWidth: 80 }}>
                              <Typography variant="subtitle2">
                                {datumService
                                  ? datumService.individual_sum + datumService.group_sum
                                  : 0}
                              </Typography>
                            </Stack>
                          </Stack>
                          <Divider />
                        </>
                      );
                    })}
                    <Divider sx={{ borderTopWidth: '3px' }} />
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        flexGrow={1}
                        sx={{ minWidth: 120 }}
                      >
                        <Typography variant="h6" noWrap>
                          Grand Total
                        </Typography>
                      </Stack>

                      <Stack direction="row" alignItems="center" sx={{ minWidth: 80 }}>
                        <Iconify
                          width={14}
                          icon="mdi:user"
                          sx={{ mr: 0.5, color: 'text.disabled' }}
                        />
                        <Typography variant="subtitle2" color="primary">
                          {datum.services.reduce(
                            (acc, service) => acc + (service.individual_sum || 0),
                            0
                          )}
                        </Typography>
                      </Stack>

                      <Stack direction="row" alignItems="center" sx={{ minWidth: 80 }}>
                        <Iconify
                          icon="fa6-solid:users"
                          width={14}
                          sx={{ mr: 0.5, color: 'text.disabled' }}
                        />
                        <Typography variant="subtitle2" color="primary">
                          {datum.services.reduce(
                            (acc, service) => acc + (service.group_sum || 0),
                            0
                          )}
                        </Typography>
                      </Stack>

                      <Stack direction="row" alignItems="center" sx={{ minWidth: 80 }}>
                        <Typography variant="subtitle2" color="primary">
                          {datum.services.reduce(
                            (acc, service) =>
                              acc + (service.individual_sum || 0) + (service.group_sum || 0),
                            0
                          )}
                        </Typography>
                      </Stack>
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Typography variant="body">No data found</Typography>
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
      </Container>
    </>
  );
}
