import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
// form
import { useFieldArray, useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  Grid,
  Stack,
  Switch,
  Typography,
  FormControlLabel,
  InputAdornment,
  IconButton,
  Divider,
  Alert,
} from '@mui/material';
// utils
import { fData } from '../../theme/utils/formatNumber';
// assets
import { countries } from '../../assets/data';
// sections
import Label from '../../theme/sections/label';
import { useSnackbar } from '../../theme/sections/snackbar';
import FormProvider, {
  RHFSelect,
  RHFSwitch,
  RHFTextField,
  RHFUploadAvatar,
} from '../../theme/sections/hook-form';
import Iconify from 'src/theme/sections/iconify/Iconify';
// rtk
import { useCreateUserMutation } from '../../redux/services/userApi';
// ----------------------------------------------------------------------

export default function CreateUserForm() {
  const [showPassword, setShowPassword] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().required('Email is required').email('Email must be a valid email address'),
    number: Yup.string().required('Phone number is required'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    name: '',
    email: '',
    number: '',
    password: '',
    allowIn: true,
  };
  // functions to build form returned by useForm() and useFieldArray() hooks
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });
  const { control, setValue, setError, handleSubmit, formState, clearErrors, reset } = methods;
  const { errors } = formState;
  const { fields, append, remove } = useFieldArray({
    name: 'members',
    control,
  });

  //rtk
  const [createUser, result] = useCreateUserMutation();

  const onSubmit = async (data) => {
    createUser({
      name: data.name,
      email: data.email,
      number: data.number,
      password: data.password,
      allowIn: data.allowIn == true ? 1 : 0,
    });
  };

  useEffect(() => {
    if (result.error) {
      //console.log(result.error.data.errors[0].message);
      enqueueSnackbar(
        result.error.data?.errors?.message ? result.error.data?.message : result.error.error,
        { variant: 'error' }
      );
      setError('afterSubmit', {
        message: result.error.data?.message ? result.error.data?.message : result.error.error,
      });
      setTimeout(() => clearErrors(), 10000);
    }
    if (result.isSuccess) {
      enqueueSnackbar('User has been created successfully');
      reset();
    }
  }, [result]);

  //  const handleDrop = useCallback(
  //   (acceptedFiles) => {
  //     const file = acceptedFiles[0];

  //     const newFile = Object.assign(file, {
  //       preview: URL.createObjectURL(file),
  //     });

  //     if (file) {
  //       setValue('avatarUrl', newFile, { shouldValidate: true });
  //     }
  //   },
  //   [setValue]
  // );

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        {/* <Grid item xs={12} md={4}>
          <Card sx={{ pt: 10, pb: 5, px: 3 }}>
            <Box sx={{ mb: 5 }}>
              <RHFUploadAvatar
                name="avatarUrl"
                maxSize={3145728}
                onDrop={handleDrop}
                helperText={
                  <Typography
                    variant="caption"
                    sx={{
                      mt: 2,
                      mx: 'auto',
                      display: 'block',
                      textAlign: 'center',
                      color: 'text.secondary',
                    }}
                  >
                    Allowed *.jpeg, *.jpg, *.png, *.gif
                    <br /> max size of {fData(3145728)}
                  </Typography>
                }
              />
            </Box>
          </Card>
        </Grid> */}

        <Grid item xs={12}>
          <Card sx={{ p: 3 }}>
            <Stack spacing={3}>
              {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
              <Box rowGap={3} columnGap={2} display="grid">
                <RHFTextField name="name" label="Full Name" />
                <RHFTextField name="email" label="Email Address" />
                <RHFTextField name="number" label="Mobile Number" />
                <RHFTextField
                  name="password"
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                          <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <RHFSwitch
                  name="allowIn"
                  label={
                    <>
                      <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                        Allow In
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        Allow incoming messages
                      </Typography>
                    </>
                  }
                  sx={{ mx: 0, width: 1 }}
                />
                <Divider />
              </Box>
            </Stack>

            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton type="submit" variant="contained" loading={result.isLoading}>
                Create User
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
