import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useCallback, useEffect, useState } from 'react';
// form
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  Grid,
  Stack,
  Switch,
  Typography,
  FormControlLabel,
  InputAdornment,
  IconButton,
  Divider,
  CardHeader,
  CardContent,
} from '@mui/material';
// utils
import { fData } from '../../../theme/utils/formatNumber';
// sections
import Label from '../../../theme/sections/label';
import { useSnackbar } from '../../../theme/sections/snackbar';
import FormProvider, {
  RHFSelect,
  RHFSwitch,
  RHFTextField,
  RHFUploadAvatar,
} from '../../../theme/sections/hook-form';
import Iconify from 'src/theme/sections/iconify/Iconify';
// react
import { useLocation, useNavigate } from 'react-router';
// rtk
import { useCreateSessionMutation, useStartSessionMutation } from 'src/redux/services/sessionApi';
//utils
import axios from 'src/theme/utils/axios';

// ----------------------------------------------------------------------

export default function CreateSessionForm() {
  const location = useLocation();
  const [sessionid, setSessionId] = useState('');
  const [heading, setHeading] = useState('Loading QR code...');
  let { id, name, email } = location.state;
  const { enqueueSnackbar } = useSnackbar();

  const NewSessionSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
  });

  const defaultValues = {
    name: '',
    isBusiness: true,
  };
  const methods = useForm({
    resolver: yupResolver(NewSessionSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  //rtk
  const [createSession, result] = useCreateSessionMutation();
  const [startSession, result2] = useStartSessionMutation();

  const onSubmit = async (data) => {
    createSession({
      userId: id,
      name: data.name,
      isBusiness: data.isBusiness,
      serverUrl: data.server_url,
    });
  };

  useEffect(() => {
    if (result.error) {
      enqueueSnackbar(
        result.error.data?.errors?.message ? result.error.data?.message : result.error.error,
        { variant: 'error' }
      );
    }
    if (result.isSuccess) {
      setSessionId(result.data.data.id);
      startSession(result.data.data.id);
      enqueueSnackbar('Wait for QR code to scan.');
    }
  }, [result]);

  const navigate = useNavigate();
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    if (result2.error) {
      enqueueSnackbar(
        result2.error.data?.errors?.message ? result2.error.data?.message : result2.error.error,
        { variant: 'error' }
      );
    }
    if (result2.isSuccess) {
      setHeading('Scan the below QR code to get started');
      enqueueSnackbar('Scan QR code to start.');
      setCounter(60);
      const timer = setTimeout(() => navigate('/user/list'), 60000);
    }
  }, [result2]);

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        {sessionid == '' && (
          <Grid item xs={12}>
            <Card sx={{ p: 3 }}>
              <Typography variant="h4">{name}</Typography>
              <Typography variant="subtitle2" sx={{ color: 'text.secondary', mb: 3 }}>
                {email}
              </Typography>
              <Box rowGap={3} columnGap={2} display="grid">
                <RHFTextField name="name" label="Please type session name" />
                <RHFTextField name="server_url" label="Server URL" />
                <RHFSwitch
                  name="isBusiness"
                  label={
                    <>
                      <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                        Business Account
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        Is this a Whatsapp business account?
                      </Typography>
                    </>
                  }
                  sx={{ mx: 0, width: 1 }}
                />
                <Divider />
              </Box>

              <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                  Create Session
                </LoadingButton>
              </Stack>
            </Card>
          </Grid>
        )}

        {sessionid && (
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Scan QR code" subheader={heading} />
              {result2.isSuccess && (
                <Typography variant="subtitle2" sx={{ p: 2 }}>
                  {`you will be redirected after: ${counter} seconds`}
                </Typography>
              )}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  p: 5,
                  backgroundColor: 'primary.main',
                }}
              >
                {result2.isLoading && (
                  <img
                    src={require('../../../assets/qr-code-animation.gif')}
                    alt="loading..."
                    width="200px"
                  />
                )}
                {result2.isSuccess && (
                  <img
                    src={`data:image/png;base64,${result2.data.qrCode}`}
                    alt="qrcode"
                    width="300px"
                  />
                )}
              </Box>
            </Card>
          </Grid>
        )}
      </Grid>
    </FormProvider>
  );
}
