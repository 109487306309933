import PropTypes from 'prop-types';
import { useState } from 'react';
import { sentenceCase } from 'change-case';
// @mui
import {
  Stack,
  Button,
  TableRow,
  Checkbox,
  MenuItem,
  TableCell,
  IconButton,
  Chip,
  Avatar,
  Typography,
  Switch,
  Fab,
  Tooltip,
  Collapse,
  Box,
  Table,
  TableHead,
  TableBody,
  Paper,
} from '@mui/material';
// utils
import { fDate } from '../../theme/utils/formatTime';
import { fFloat, fNumber } from '../../theme/utils/formatNumber';
// sections
import { useSnackbar } from '../../theme/sections/snackbar';
import Label from '../../theme/sections/label';
import Image from '../../theme/sections/image';
import Iconify from '../../theme/sections/iconify';
import MenuPopover from '../../theme/sections/menu-popover';
import ConfirmDialog from '../../theme/sections/confirm-dialog';
import { useNavigate } from 'react-router-dom';
import { isAfter } from 'date-fns';
import { useDeleteBucketTypeMutation } from 'src/redux/services/bucketApi';

// ----------------------------------------------------------------------

UserBucketTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  onViewRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
  handleStatus: PropTypes.func,
};

export default function UserBucketTableRow({
  row,
  selected,
  onSelectRow,
  onDeleteRow,
  onEditRow,
  onViewRow,
  handleStatus,
}) {
  const { id, type, bucket, used, expiryDate, status, bucketTypes, createdAt } = row;

  const currentDate = new Date();
  const { enqueueSnackbar } = useSnackbar(); // toast

  const [openConfirm, setOpenConfirm] = useState(false);
  const [open, setOpen] = useState(false);

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };
  const [openPopover, setOpenPopover] = useState(null);
  const [openSubConfirm, setOpenSubConfirm] = useState(false);
  const [bucketTypeId, setBucketTypeId] = useState(null);
  const [deleteBucketType] = useDeleteBucketTypeMutation();

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleSubOpen = (bucketTypeId) => {
    setOpenSubConfirm(true);
    setBucketTypeId(bucketTypeId);
  };

  const handleSubClose = () => {
    setOpenSubConfirm(false);
  };

  const handleSubDelete = () => {
    //console.log(`id: ${bucketTypeId}`);
    deleteBucketType(bucketTypeId);
    setOpenSubConfirm(false);
    enqueueSnackbar('Service has been deleted successfully');
  };

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell>
          <Label
            variant="filled"
            color={type == 'postpaid' ? 'secondary' : 'info'}
            sx={{ fontSize: 16 }}
          >
            {type}
          </Label>
        </TableCell>

        <TableCell align="center">
          <Fab
            color={open ? 'primary' : 'inherit'}
            size="small"
            variant="soft"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <Iconify icon="iconamoon:arrow-up-2" width={26} />
            ) : (
              <Iconify icon="iconamoon:arrow-down-2" width={26} />
            )}
          </Fab>
        </TableCell>

        <TableCell>
          <Typography variant="subtitle2">{fFloat(bucket)} Rs.</Typography>
        </TableCell>

        <TableCell>
          <Typography variant="subtitle2">{fFloat(used)} Rs.</Typography>
        </TableCell>

        <TableCell>
          <Typography variant="subtitle2">{fFloat(bucket - used)} Rs.</Typography>
        </TableCell>

        <TableCell>
          <Typography
            variant="subtitle2"
            color={isAfter(new Date(expiryDate), new Date()) ? 'default' : 'error'}
          >
            {fDate(expiryDate)}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography variant="subtitle2">{fDate(createdAt)}</Typography>
        </TableCell>

        <TableCell>
          {/* <Label
            variant="soft"
            sx={{ fontSize: 15 }}
            color={
              status == 1
                ? isAfter(new Date(expiryDate), new Date())
                  ? 'success'
                  : 'error'
                : 'default'
            }
          > </Label>*/}
          {status == 1 ? (
            isAfter(new Date(expiryDate), new Date()) ? (
              <Switch defaultChecked onChange={handleStatus} />
            ) : (
              <Label variant="soft" sx={{ fontSize: 15 }} color="error">
                Expired
              </Label>
            )
          ) : (
            <Switch onChange={handleStatus} />
          )}
        </TableCell>

        <TableCell>
          <Fab color="secondary" size="small" variant="soft" sx={{ mr: '10px' }}>
            <Iconify icon="eva:edit-fill" width={26} onClick={onEditRow} />
          </Fab>
          <Fab color="error" size="small" variant="soft">
            <Iconify icon="eva:trash-2-outline" width={26} onClick={handleOpenConfirm} />
          </Fab>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ pb: 2 }} colSpan={9}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Paper
              variant="outlined"
              sx={{
                py: 2,
                borderRadius: 1.5,
                ...(open && {
                  boxShadow: (theme) => theme.customShadows.z20,
                }),
              }}
            >
              <Typography variant="h6" sx={{ m: 2, mt: 0 }}>
                Service Details
              </Typography>

              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Service</TableCell>
                    <TableCell align="center">Individual Rate</TableCell>
                    <TableCell align="center">Group Rate</TableCell>
                    <TableCell align="center">
                      Total Est. Bucket <br />
                      <Chip
                        variant="soft"
                        label="Individual"
                        size="small"
                        icon={<Iconify icon="mdi:user" />}
                        sx={{ mr: '5px' }}
                      />
                      <Chip
                        variant="soft"
                        label="Group"
                        size="small"
                        icon={<Iconify icon="mdi:user-group" />}
                      />
                    </TableCell>
                    <TableCell align="center">
                      Remaining Est. Bucket <br />
                      <Chip
                        variant="soft"
                        label="Individual"
                        size="small"
                        icon={<Iconify icon="mdi:user" />}
                        sx={{ mr: '5px' }}
                      />
                      <Chip
                        variant="soft"
                        label="Group"
                        size="small"
                        icon={<Iconify icon="mdi:user-group" />}
                      />
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bucketTypes.map((bucketType) => (
                    <TableRow key={bucketType.id}>
                      <TableCell>
                        <Chip
                          variant="soft"
                          size="large"
                          label={bucketType.service.name}
                          color={type == 'postpaid' ? 'secondary' : 'info'}
                          icon={
                            <Iconify
                              width={24}
                              icon={
                                bucketType.service.name == 'Text'
                                  ? 'icon-park-twotone:text-message'
                                  : `mdi:${bucketType.service.name.toLowerCase()}`
                              }
                            />
                          }
                        />
                      </TableCell>
                      <TableCell align="center">{fFloat(bucketType.rate)} Rs.</TableCell>
                      <TableCell align="center">{fFloat(bucketType.group_rate)} Rs.</TableCell>
                      <TableCell align="center">
                        <Chip
                          variant="outlined"
                          label={fNumber(bucket / bucketType.rate)}
                          icon={<Iconify icon="mdi:user" />}
                          sx={{ mr: '5px' }}
                        />

                        <Chip
                          variant="outlined"
                          label={fNumber(bucket / bucketType.group_rate)}
                          icon={<Iconify icon="mdi:user-group" />}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Chip
                          variant="outlined"
                          label={fNumber(used / bucketType.rate)}
                          icon={<Iconify icon="mdi:user" />}
                          sx={{ mr: '5px' }}
                        />
                        <Chip
                          variant="outlined"
                          label={fNumber(used / bucketType.group_rate)}
                          icon={<Iconify icon="mdi:user-group" />}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Fab color="error" size="small" variant="soft">
                          <Iconify
                            icon="eva:trash-2-outline"
                            width={26}
                            onClick={() => handleSubOpen(bucketType.id)}
                          />
                        </Fab>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          </Collapse>
        </TableCell>
      </TableRow>

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title="Delete"
        content="Are you sure want to delete this bucket?"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            Delete
          </Button>
        }
      />

      <ConfirmDialog
        open={openSubConfirm}
        onClose={handleSubClose}
        title="Delete"
        content="Are you sure want to delete this service?"
        action={
          <Button variant="contained" color="error" onClick={handleSubDelete}>
            Delete
          </Button>
        }
      />
    </>
  );
}
