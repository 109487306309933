import { Container } from '@mui/material';
import { Helmet } from 'react-helmet-async';
// components
import PrivilegeForm from 'src/components/settings/PrivilegeForm';
// sections
import { useSettingsContext } from '../../theme/sections/settings';
import CustomBreadcrumbs from '../../theme/sections/custom-breadcrumbs';

// ----------------------------------------------------------------------

export default function PrivilegePage() {
  const { themeStretch } = useSettingsContext();

  return (
    <>
      <Helmet>
        <title> Privileges | {process.env.REACT_APP_NAME} </title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'xlg'}>
        <CustomBreadcrumbs
          heading="Privileges"
          links={[
            {
              name: 'Settings',
              href: '/settings',
            },
            {
              name: 'Privileges',
            },
          ]}
        />
        <PrivilegeForm />
      </Container>
    </>
  );
}
