import PropTypes from 'prop-types';
import { Stack, InputAdornment, TextField, MenuItem, Button, Grid } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
// sections
import Iconify from 'src/theme/sections/iconify';

// ----------------------------------------------------------------------

//const INPUT_WIDTH = 160;

MessageLogFilter.propTypes = {
  isFiltered: PropTypes.bool,
  optionsStatus: PropTypes.arrayOf(PropTypes.string),
  optionsChannel: PropTypes.arrayOf(PropTypes.string),
  optionsUser: PropTypes.arrayOf(PropTypes.string),
  downloading: PropTypes.string,
  filterStatus: PropTypes.string,
  filterRecipient: PropTypes.string,
  filterSender: PropTypes.string,
  filterUser: PropTypes.number,
  filterMessage: PropTypes.string,
  filterChannel: PropTypes.string,
  filterStartDate: PropTypes.instanceOf(Date),
  filterEndDate: PropTypes.instanceOf(Date),
  onFilterStatus: PropTypes.func,
  onFilterRecipient: PropTypes.func,
  onFilterSender: PropTypes.func,
  onFilterMessage: PropTypes.func,
  onFilterChannel: PropTypes.func,
  onFilterUser: PropTypes.func,
  onResetFilter: PropTypes.func,
  onFilterStartDate: PropTypes.func,
  onFilterEndDate: PropTypes.func,
  onSubmit: PropTypes.func,
  exportToCSV: PropTypes.func,
};

export default function MessageLogFilter({
  isFiltered,
  optionsStatus,
  optionsChannel,
  optionsUser,
  downloading,
  filterStatus,
  filterUser,
  filterRecipient,
  filterSender,
  filterMessage,
  filterChannel,
  filterStartDate,
  filterEndDate,
  onFilterUser,
  onFilterStatus,
  onFilterRecipient,
  onFilterSender,
  onFilterMessage,
  onFilterChannel,
  onResetFilter,
  onFilterStartDate,
  onFilterEndDate,
  onSubmit,
  exportToCSV,
}) {
  return (
    <Grid container spacing={2} sx={{ px: 2.5, py: 3 }}>
      <Grid item xs={12} md={3} lg={3}>
        <DatePicker
          label="Start Date *"
          value={filterStartDate}
          onChange={onFilterStartDate}
          renderInput={(params) => <TextField {...params} fullWidth />}
        />
      </Grid>

      <Grid item xs={12} md={3} lg={3}>
        <DatePicker
          label="End date *"
          value={filterEndDate}
          onChange={onFilterEndDate}
          renderInput={(params) => <TextField {...params} fullWidth />}
        />
      </Grid>

      <Grid item xs={12} md={3} lg={3}>
        <TextField
          fullWidth
          select
          label="Users"
          value={filterUser}
          onChange={onFilterUser}
          SelectProps={{
            MenuProps: {
              PaperProps: {
                sx: { maxHeight: 220 },
              },
            },
          }}
        >
          <MenuItem
            selected
            key="0"
            value="all"
            sx={{
              mx: 1,
              borderRadius: 0.75,
              typography: 'body2',
              textTransform: 'capitalize',
            }}
          >
            All Users
          </MenuItem>
          {optionsUser.map((option) => (
            <MenuItem
              key={option.id}
              value={option.id}
              sx={{
                mx: 1,
                borderRadius: 0.75,
                typography: 'body2',
                textTransform: 'capitalize',
              }}
            >
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12} md={3} lg={3}>
        <TextField
          fullWidth
          select
          label="Status Type"
          value={filterStatus}
          onChange={onFilterStatus}
          SelectProps={{
            MenuProps: {
              PaperProps: {
                sx: { maxHeight: 220 },
              },
            },
          }}
        >
          {optionsStatus.map((option) => (
            <MenuItem
              key={option}
              value={option}
              sx={{
                mx: 1,
                borderRadius: 0.75,
                typography: 'body2',
                textTransform: 'capitalize',
              }}
            >
              {option}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12} md={3} lg={3}>
        <TextField
          fullWidth
          select
          label="Channel"
          value={filterChannel}
          onChange={onFilterChannel}
          SelectProps={{
            MenuProps: {
              PaperProps: {
                sx: { maxHeight: 220 },
              },
            },
          }}
        >
          {optionsChannel.map((option) => (
            <MenuItem
              key={option}
              value={option}
              sx={{
                mx: 1,
                borderRadius: 0.75,
                typography: 'body2',
                textTransform: 'capitalize',
              }}
            >
              {option}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      {(filterChannel == 'all' || filterChannel == 'OUT') && (
        <Grid item xs={12} md={3} lg={3}>
          <TextField
            fullWidth
            value={filterRecipient}
            onChange={onFilterRecipient}
            placeholder="Find recipient"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      )}

      {(filterChannel == 'all' || filterChannel == 'IN') && (
        <Grid item xs={12} md={3} lg={3}>
          <TextField
            fullWidth
            value={filterSender}
            onChange={onFilterSender}
            placeholder="Find sender"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      )}

      <Grid item xs={12} md={3} lg={3}>
        <TextField
          fullWidth
          value={filterMessage}
          onChange={onFilterMessage}
          placeholder="Find by message"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12} md={6} lg={6}>
        {isFiltered && (
          <Button
            size="large"
            variant="soft"
            color="error"
            sx={{ flexShrink: 0 }}
            onClick={onResetFilter}
            startIcon={<Iconify icon="eva:trash-2-outline" />}
          >
            Clear Filters
          </Button>
        )}

        <Button
          color="primary"
          size="large"
          variant="soft"
          sx={{ ml: 1 }}
          onClick={onSubmit}
          startIcon={<Iconify icon="material-symbols:search" />}
        >
          Filter Logs
        </Button>

        <Button variant="soft" color="warning" size="large" onClick={exportToCSV} sx={{ ml: 1 }}>
          <Iconify icon="mdi:microsoft-excel" /> &nbsp;{' '}
          {downloading ? 'processing..' : 'Export to excel'}
        </Button>
      </Grid>
    </Grid>
  );
}
