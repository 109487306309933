import { Card, Container, Divider, Pagination, TablePagination, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
// sections
import { useSettingsContext } from '../../theme/sections/settings';
import CustomBreadcrumbs from '../../theme/sections/custom-breadcrumbs';
import { useEffect, useState } from 'react';
import MessageLogTable from 'src/components/report/MessageLogTable';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'src/theme/sections/snackbar';
import { fDateYMD } from 'src/theme/utils/formatTime';
import axios from 'src/theme/utils/axios';
//components
import MessageLogFilter from 'src/components/report/MessageLogFilter';
// excel export
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
// ----------------------------------------------------------------------

export default function MessageLogPage() {
  const { themeStretch } = useSettingsContext();
  const { enqueueSnackbar } = useSnackbar();

  // user details
  const { user } = useSelector((state) => state.auth);
  const config = {
    headers: {
      Authorization: `Bearer ${user.accessToken}`,
    },
  };

  const [page, setPage] = useState(1);

  const [tableData, setTableData] = useState([]);

  const [users, setUsers] = useState([]);

  const [loading, setLoading] = useState(true);

  const [downloading, setDownloading] = useState(false);

  const [metaData, setMetaData] = useState([]);

  const [filterRecipient, setFilterRecipient] = useState('');

  const [filterSender, setFilterSender] = useState('');

  const [filterMessage, setFilterMessage] = useState('');

  const [filterStatus, setFilterStatus] = useState('all');

  const [filterStartDate, setFilterStartDate] = useState(new Date());

  const [filterEndDate, setFilterEndDate] = useState(new Date());

  const [filterChannel, setFilterChannel] = useState('all');

  const [filterUser, setFilterUser] = useState('');

  const [allDataForExport, setAllDataForExport] = useState([]);

  const STATUS_OPTIONS = ['all', 'Sent', 'Pending', 'Failed'];
  const CHANNEL_OPTIONS = ['all', 'OUT', 'IN'];

  const handleChangePage = (event, number) => {
    setPage(number);
  };

  const handleFilterSender = (event) => {
    setFilterSender(event.target.value);
  };

  const handleFilterRecipient = (event) => {
    setFilterRecipient(event.target.value);
  };

  const handleFilterMessage = (event) => {
    setFilterMessage(event.target.value);
  };

  const handleFilterChannel = (event) => {
    setFilterChannel(event.target.value);
  };

  const handleFilterStatus = (event) => {
    setFilterStatus(event.target.value);
  };

  const handleFilterUser = (event) => {
    setFilterUser(event.target.value);
  };

  const handleResetFilter = () => {
    setFilterRecipient('');
    setFilterUser('');
    setFilterSender('');
    setFilterMessage('');
    setFilterStatus('all');
    setFilterChannel('all');
    setFilterStartDate(null);
    setFilterEndDate(null);
    setPage(1);
    setMetaData([]);
    setTableData([]);
    setLoading(false);
  };

  const isFiltered =
    filterUser !== '' ||
    filterRecipient !== '' ||
    filterSender !== '' ||
    filterMessage !== '' ||
    filterStatus !== 'all' ||
    filterChannel !== 'all' ||
    (!!filterStartDate && !!filterEndDate);

  const callApi = async (isPage) => {
    const payload = {};
    if (filterStartDate === null || filterEndDate === null) {
      enqueueSnackbar('Please select date range', { variant: 'error' });
    } else {
      setLoading(true);
      payload.fromDate = fDateYMD(filterStartDate);
      payload.toDate = fDateYMD(filterEndDate);
      if (filterUser !== '' && filterUser !== 'all') payload.user = filterUser;
      if (filterRecipient !== '') payload.recipient = filterRecipient;
      if (filterSender !== '') payload.sender = filterSender;
      if (filterMessage !== '') payload.message = filterMessage;
      if (filterStatus !== 'all') payload.status = filterStatus;
      if (filterChannel !== 'all') payload.channel = filterChannel;
      if (isPage == 1) payload.page = page;

      //console.log(payload);
      const response = await axios.post('logs/message/filter', payload, config);
      if (response.data.logs) {
        //console.log(response.data.logs);
        setMetaData(response.data.meta);
        setTableData(response.data.logs);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    axios
      .get('users', config)
      .then((response) => {
        const userData = response.data;
        //console.log(userData);
        setUsers(userData);
      })
      .catch((error) => {
        console.error('Error fetching users:', error);
      });
  }, []);

  //on submit filter
  const handleOnSubmit = async () => {
    callApi(0);
  };

  // on page change
  useEffect(() => {
    callApi(1);
  }, [page]);

  // excel export
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const fetchAllData = async () => {
    const payload = {};
    if (filterStartDate === null || filterEndDate === null) {
      enqueueSnackbar('Please select date range', { variant: 'error' });
      return [];
    } else {
      setDownloading(true);
      payload.fromDate = fDateYMD(filterStartDate);
      payload.toDate = fDateYMD(filterEndDate);
      if (filterUser !== '' && filterUser !== 'all') payload.user = filterUser;
      if (filterRecipient !== '') payload.recipient = filterRecipient;
      if (filterSender !== '') payload.sender = filterSender;
      if (filterMessage !== '') payload.message = filterMessage;
      if (filterStatus !== 'all') payload.status = filterStatus;
      if (filterChannel !== 'all') payload.channel = filterChannel;

      let allData = [];
      let currentPage = 1;
      let lastPage = 1;

      do {
        payload.page = currentPage;
        const response = await axios.post('logs/message/filter', payload, config);
        if (response.data.logs) {
          allData = allData.concat(response.data.logs);
          lastPage = response.data.meta.last_page;
          currentPage += 1;
        } else {
          break;
        }
      } while (currentPage <= lastPage);

      setAllDataForExport(allData);
      setDownloading(false);
      return allData;
    }
  };

  const exportToCSV = async () => {
    const dataToExport = await fetchAllData();

    if (dataToExport.length > 0) {
      // Add user name to each data row
      const transformedData = dataToExport.map((item) => ({
        ...item,
        user: item.user.name,
      }));

      const fileName = `message_logs_${fDateYMD(filterStartDate)}_${fDateYMD(filterEndDate)}`;
      const ws = XLSX.utils.json_to_sheet(transformedData);
      const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    } else {
      enqueueSnackbar('No data to export', { variant: 'error' });
    }
  };

  return (
    <>
      <Helmet>
        <title>Message Logs | {process.env.REACT_APP_NAME} </title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'xlg'}>
        <CustomBreadcrumbs
          heading="Message Logs"
          links={[
            { name: 'Dashboard', href: '/dashboard' },
            {
              name: 'Logs',
              href: '/logs',
            },
            { name: 'Message' },
          ]}
        />

        <Card>
          <MessageLogFilter
            isFiltered={isFiltered}
            optionsStatus={STATUS_OPTIONS}
            optionsChannel={CHANNEL_OPTIONS}
            optionsUser={users}
            filterStatus={filterStatus}
            filterRecipient={filterRecipient}
            filterMessage={filterMessage}
            filterChannel={filterChannel}
            filterUser={filterUser}
            filterStartDate={filterStartDate}
            filterEndDate={filterEndDate}
            onFilterStatus={handleFilterStatus}
            onFilterRecipient={handleFilterRecipient}
            onFilterSender={handleFilterSender}
            onFilterUser={handleFilterUser}
            onFilterMessage={handleFilterMessage}
            onFilterChannel={handleFilterChannel}
            onResetFilter={handleResetFilter}
            onFilterStartDate={(newValue) => {
              setFilterStartDate(newValue);
            }}
            onFilterEndDate={(newValue) => {
              setFilterEndDate(newValue);
            }}
            onSubmit={handleOnSubmit}
            exportToCSV={exportToCSV}
            downloading={downloading}
          />

          <MessageLogTable tableData={tableData} page={page} loading={loading} config={config} />

          <Divider />

          {!loading && (
            <Pagination
              sx={{ p: 2 }}
              component="div"
              count={metaData.last_page}
              page={page}
              onChange={handleChangePage}
            />
          )}
        </Card>
      </Container>
    </>
  );
}
