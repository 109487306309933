import PropTypes from 'prop-types';
import { useState } from 'react';
import { sentenceCase } from 'change-case';
// @mui
import {
  Stack,
  Button,
  TableRow,
  Checkbox,
  MenuItem,
  TableCell,
  IconButton,
  Chip,
  Avatar,
  Typography,
  Switch,
  Fab,
  Tooltip,
} from '@mui/material';
// utils
import { fDate } from '../../../theme/utils/formatTime';
import { fCurrency } from '../../../theme/utils/formatNumber';
// sections
import Label from '../../../theme/sections/label';
import Image from '../../../theme/sections/image';
import Iconify from '../../../theme/sections/iconify';
import MenuPopover from '../../../theme/sections/menu-popover';
import ConfirmDialog from '../../../theme/sections/confirm-dialog';
import { useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

UserTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  onViewRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
};

export default function UserTableRow({
  row,
  selected,
  onSelectRow,
  onDeleteRow,
  onEditRow,
  onViewRow,
}) {
  const { id, status, name, email, number, allowIn, created_at, session } = row;

  const [openConfirm, setOpenConfirm] = useState(false);

  const [openPopover, setOpenPopover] = useState(null);

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const navigate = useNavigate();

  const handleClick = (e, id, name, email) => {
    e.preventDefault();
    navigate('/whatsapp/session/create', { state: { id: id, name: name, email: email } });
  };

  const handleRestartClick = (e, sessionid) => {
    e.preventDefault();
    navigate('/whatsapp/session/restart', { state: { sessionid: sessionid } });
  };

  const handleViewLogClick = (e, sessionid, name) => {
    e.preventDefault();
    navigate('/whatsapp/user/session/log', { state: { sessionid: sessionid, name: name } });
  };

  const handleViewBucket = (e, name, userId) => {
    e.preventDefault();
    navigate('/user/bucket', { state: { userId, name } });
  };

  const statusLabel = (status) => {
    switch (status) {
      case 0:
        return (
          <Label variant="soft" color="default">
            Inactive
          </Label>
        );
      case 1:
        return (
          <Label variant="soft" color="success">
            &nbsp; Connected
          </Label>
        );
      case 2:
        return (
          <Label variant="soft" color="warning">
            Scan QR Code
          </Label>
        );
      case 3:
        return (
          <Label variant="soft" color="error">
            Closed (Browser Closed)
          </Label>
        );
      case 4:
        return (
          <Label variant="soft" color="info">
            Logged Out
          </Label>
        );
    }
  };
  return (
    <>
      <TableRow hover selected={selected}>
        {/* <TableCell align="center">
          <Stack direction="row" alignItems="center" spacing={2}>
            <Avatar
              alt={name}
              src={image ? image : require('../../../assets/profile/group_dp.png')}
            />
          </Stack>
        </TableCell> */}
        <TableCell>
          <Switch defaultChecked={status == 1 ? true : false} />
        </TableCell>
        <TableCell>
          <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
            {name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {email}
          </Typography>
        </TableCell>
        <TableCell>{number}</TableCell>
        <TableCell>
          <Switch defaultChecked={allowIn == 1 ? true : false} />
        </TableCell>
        <TableCell>
          {session ? (
            statusLabel(session.status)
          ) : (
            <Label variant="soft" color="default">
              no session
            </Label>
          )}
        </TableCell>
        <TableCell>
          {session ? (
            // <Button
            //   variant="contained"
            //   size="small"
            //   color="info"
            //   onClick={(e) => handleRestartClick(e, session.id)}
            // >
            //   Restart
            // </Button>
            <Tooltip title="View Session Log">
              <Fab
                color="primary"
                onClick={(e) => handleViewLogClick(e, session.id, name)}
                size="small"
                variant="soft"
              >
                <Iconify icon="icon-park-outline:view-grid-list" width={26} />
              </Fab>
            </Tooltip>
          ) : (
            <Tooltip title="Create Session">
              <Fab
                color="primary"
                onClick={(e) => handleClick(e, id, name, email)}
                size="small"
                variant="soft"
              >
                <Iconify icon="mdi:qrcode-edit" width={25} />
              </Fab>
            </Tooltip>
          )}
        </TableCell>
        <TableCell>{fDate(created_at)}</TableCell>
        {name != 'Master' && (
          <TableCell
            align="right"
            sx={{
              display: 'flex',
              alignItems: 'right',
              justifyContent: 'right',
              flexWrap: 'wrap',
              '& > *': { m: '3px !important' },
            }}
          >
            <Fab color="error" size="small" variant="soft">
              <Iconify icon="eva:trash-2-outline" width={26} onClick={handleOpenConfirm} />
            </Fab>

            <Fab color="secondary" size="small" variant="soft">
              <Iconify icon="eva:edit-fill" width={26} onClick={onEditRow} />
            </Fab>

            <Fab color="info" size="small" variant="soft">
              <Iconify
                icon="iconoir:user-cart"
                width={26}
                onClick={(e) => handleViewBucket(e, name, id)}
              />
            </Fab>
            {/* <IconButton color={openPopover ? 'primary' : 'default'} onClick={handleOpenPopover}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton> */}
          </TableCell>
        )}
      </TableRow>

      {/* <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            handleOpenConfirm();
            handleClosePopover();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="eva:trash-2-outline" />
          Delete
        </MenuItem>

        <MenuItem
          onClick={() => {
            onEditRow();
            handleClosePopover();
          }}
        >
          <Iconify icon="eva:edit-fill" />
          Edit
        </MenuItem>
      </MenuPopover> */}

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            Delete
          </Button>
        }
      />
    </>
  );
}
