import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const privilegeApi = createApi({
  reducerPath: 'privilegeApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    //set header
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.user.accessToken;
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    // Get all roles
    roles: builder.query({
      query: () => '/roles',
    }),

    // Get all permissions
    permissions: builder.query({
      query: () => '/permissions',
    }),

    // Get all permissions against a role
    rolePermission: builder.mutation({
      query: (roleId) => ({
        url: `/role/permission/${roleId}`,
      }),
    }),

    // save all permissions against a role
    saveRolePermission: builder.mutation({
      query: () => ({
        url: '/role/permission',
        method: 'POST',
      }),
    }),

    // Get all permissions against a user
    userPermission: builder.mutation({
      query: (userId) => ({
        url: `/users/permission/${userId}`,
      }),
    }),

    // save all permissions against a user
    saveUserPermission: builder.mutation({
      query: (data) => ({
        url: '/users/permission',
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const {
  usePermissionsQuery,
  useRolesQuery,
  useRolePermissionMutation,
  useSaveRolePermissionMutation,
  useUserPermissionMutation,
  useSaveUserPermissionMutation,
} = privilegeApi;
